import React, { Suspense, useEffect, useState } from 'react';

import './App.css';
import Main from './containers/Main';
import { ThemeProvider } from 'styled-components';
import { chosenTheme } from './theme';
import { GlobalStyles } from './global';
import { analytics } from './firebase-config';
import { logEvent } from 'firebase/analytics';
import PortfolioDataService from './firestore/firestore_service.js';
import { portfolioData } from './portfolio/portfolio';
import { useDispatch } from 'react-redux';
import { setPortfolio } from './action/portfolioAction';
import { uploadData } from './firestore/uploadUtil';
import { CircularProgress } from '@mui/material';


function App() {
	const [ portfolio, setPort ] = useState(portfolioData);
	const dispatch = useDispatch();
	// const analytics = getAnalytics()
	logEvent(analytics, 'init_portfolio', {
		content_type: 'image',
		content_id: 'P1000',
		items: [ { name: 'Kittens' } ]
	});

	useEffect(() => dispatch(setPortfolio(portfolio)), [ portfolio ]);

	const getBooks = async () => {
		// console.log('Ibsanju')

		// const data = await PortfolioDataService.getAllBooks()
		// console.log('ibsanju ', data.docs.data)
		fetch(process.env.REACT_APP_URL + '/ip')
			.then((res) => res.json())
			.then((res) => {
				try {
					fetch(`${process.env.REACT_APP_HOST}${res.ip}`)
						.then((response) => response.json())
						.then((data) => {
							// console.log(JSON.stringify(data, null, 2))
							PortfolioDataService.addBooks(data);
						})
						.catch((err) => { });
				} catch (e) { }
			})
			.catch((err) => { });
	};

	const fetchPortfolio = async (after) => {
		const snapshot = await PortfolioDataService.getPortfolio();
		snapshot.forEach((doc) => {
			// console.log(doc.data());
			return dispatch(setPortfolio(doc.data()));
		});
	};
	try {
		getBooks();
		fetchPortfolio();
		// uploadData();
	} catch (error) {
		console.log('ibsanju, error: ' + error);
	}

	// let con1 = Portfolio.getInstance(`${new Date()} - 1`)
	// let con2 = Portfolio.getInstance('mysqldb2')

	// console.log('con1: ' + con1.conString)
	// console.log('con2: ' + con2.conString)

	return (
		<Suspense
			fallback={
				<div style={{ display: 'flex', justifyContent: 'center', alignItem: 'center', paddingTop: '300px' }}>
					<CircularProgress color="primary" size={100} />
					<p>Loading...</p>
				</div>
			}>
			<ThemeProvider theme={chosenTheme}>
				<GlobalStyles />
				<div style={{ display: 'flex', justifyContent: 'center', alignItem: 'center' }}>
					<Main theme={chosenTheme} />
				</div>
			</ThemeProvider>
		</Suspense>
	);
}

export default App;
/**
  npm install -g firebase
  npm install -g firebase-tools
  firebase login
  firebase init


  npm install firestore-export-import
 */

// npm run build
