import { UPDATE_PORTFOLIO } from "../reducer/portfolioReducer";

export const setPortfolio = (data) => (dispatch)=>{
	if (data) {
		// console.log('Ibsanju: setPortfolio: new data action')
		dispatch({ type: UPDATE_PORTFOLIO, payload: data });
	} else {
		// console.log('error now payload found!');
	}
};
