/* Change this file to get your personal Porfolio */

import ProjectsData from "../shared/opensource/projects.json";


// Website related settings
const settings = {
	isSplash: false // Change this to false if you don't want Splash screen.
}

//SEO Related settings
const seo = {
	title: "Bharath Kumar Revana",
	description: 'A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact. Empowering Digital Transformation through Innovative Software Solutions',
	og: {
		title: "Bharath Kumar Revana's Portfolio",
		type: 'website',
		url: 'http://ibsanju.github.io/'
	}
}

//Home Page
const greeting = {
	title: 'Bharath Kumar Revana',
	logo_name: 'Bharath Revana',
	nickname: 'IbsanjU',
	subTitle: 'A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact. Empowering Digital Transformation through Innovative Software Solutions',
	resumeLink: 'https://drive.google.com/file/d/1B_ZEkrUtB-Jjj15L9ODX4PJtVAL0rUWp/view',
	portfolio_repository: 'https://github.com/IbsanjU/portfolio_v2'
}

const socialMediaLinks = [
	/* Your Social Media Link */
	// github: "https://github.com/ashutosh1919",
	// linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
	// gmail: "ashutoshhathidara98@gmail.com",
	// gitlab: "https://gitlab.com/ashutoshhathidara98",
	// facebook: "https://www.facebook.com/laymanbrother.19/",
	// twitter: "https://twitter.com/ashutosh_1919",
	// instagram: "https://www.instagram.com/layman_brother/"
	{
		name: 'LinkedIn',
		link: 'https://www.linkedin.com/in/Bharath-Kumar-R/',
		fontAwesomeIcon: 'fa-linkedin-in', // Reference https://fontawesome.com/icons/linkedin-in?style=brands
		backgroundColor: '#0e76a8' // Reference https://simpleicons.org/?q=linkedin
	},
	{
		name: 'Github',
		link: 'https://github.com/ibsanju',
		fontAwesomeIcon: 'fa-github', // Reference https://fontawesome.com/icons/github?style=brands
		backgroundColor: '#181F17' // Reference https://simpleicons.org/?q=github
	},
	{
		name: 'Instagram',
		link: 'https://www.instagram.com/IbsanjU/',
		fontAwesomeIcon: 'fa-instagram', // Reference https://fontawesome.com/icons/instagram?style=brands
		backgroundColor: 'rgb(239, 71, 111)' // Reference https://simpleicons.org/?q=instagram
	},
	{
		name: 'Gmail',
		link: 'mailto:bharath2668@gmail.com',
		fontAwesomeIcon: 'fa-google', // Reference https://fontawesome.com/icons/google?style=brands
		backgroundColor: '#F14F36' // Reference https://simpleicons.org/?q=gmail
	},
	{
		name: 'Whatsapp',
		link: 'https://wa.me/13065107008',
		fontAwesomeIcon: 'fa-whatsapp', // Reference https://fontawesome.com/icons/google?style=brands
		backgroundColor: '#25D366' // Reference https://simpleicons.org/?q=gmail
	}
	// {
	// 	name: 'Facebook',
	// 	link: 'https://www.facebook.com/IbharathsanjU/',
	// 	fontAwesomeIcon: 'fa-facebook-f', // Reference https://fontawesome.com/icons/facebook-f?style=brands
	// 	backgroundColor: '#1877F2' // Reference https://simpleicons.org/?q=facebook
	// },
	// {
	// 	name: 'Twitter',
	// 	link: 'https://twitter.com/IbsanjU',
	// 	fontAwesomeIcon: 'fa-twitter', // Reference https://fontawesome.com/icons/twitter?style=brands
	// 	backgroundColor: '#1DA1F2' // Reference https://simpleicons.org/?q=twitter
	// },
	// {
	//   name: "YouTube",
	//   link: "https://www.youtube.com/channel/UC_amoXmmxSY9KusoDczDTXQ",
	//   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
	//   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
	// },
]

const skills = {
	data: [
		{
			title: 'Full Stack Development',
			fileName: 'FullStackImg',
			skills: [
				'⚡Crafting responsive front ends for websites using PolymerJS, React, and Flutter.',
				'⚡Crafting mobile apps with Android Native and Flutter.',
				'⚡Building robust backends with Node, Express, GraphQL, and Sequelize-ORM.',
				'⚡Expertise in cloud deployment for applications.',
				'⚡Proficiency in Java, JavaScript, HTML5, NodeJS, and Flutter.',
				'⚡Utilizing AWS, Google Cloud, and Azure for cloud solutions.',
				'⚡Implementing Selenium for automated testing and quality assurance.',
			],
			softwareSkills: [
				{
					skillName: 'Java',
					fontAwesomeClassname: 'logos:java',
					style: {
						color: '#007396'
					}
				},
				{
					skillName: 'JavaScript',
					fontAwesomeClassname: 'simple-icons:javascript',
					style: {
						backgroundColor: '#000000',
						color: '#F7DF1E'
					}
				},
				{
					skillName: 'Android',
					fontAwesomeClassname: 'fa-android',
					style: {
						color: '#a4c639'
					}
				},
				{
					skillName: 'NodeJS',
					fontAwesomeClassname: 'simple-icons:nodedotjs',
					style: {
						color: '#339933'
					}
				},
				{
					skillName: 'ReactJS',
					fontAwesomeClassname: 'simple-icons:react',
					style: {
						color: '#61DAFB'
					}
				},
				{
					skillName: 'Flutter',
					fontAwesomeClassname: 'simple-icons:flutter',
					style: {
						color: '#059AF2'
					}
				},
				// {
				// 	skillName: 'NPM',
				// 	fontAwesomeClassname: 'simple-icons:npm',
				// 	style: {
				// 		color: '#CB3837'
				// 	}
				// },
				{
					skillName: 'HTML5',
					fontAwesomeClassname: 'simple-icons:html5',
					style: {
						color: '#E34F26'
					}
				},
				{
					skillName: 'Selenium',
					fontAwesomeClassname: 'skill-icons:selenium',
					style: {
						color: '#E34F26'
					}
				},
				{
					skillName: 'Go-lang',
					fontAwesomeClassname: 'cib:go',
					style: {
						color: '#059AF2'
					}
				},
				{
					skillName: 'aws',
					fontAwesomeClassname: 'mdi:aws',
					style: {
						color: '#E34F26'
					}
				},
				{
					skillName: 'G-cloud',
					fontAwesomeClassname: 'logos:google-cloud',
					style: {
						color: '#059AF2'
					}
				},
				{
					skillName: 'Azure',
					fontAwesomeClassname: 'skill-icons:azure-light',
					style: {
						color: '#059AF2'
					}
				}
			]
		}
	]
}

// Education Page
const competitiveSites = {
	competitiveSites: []
}

const degrees = {
	degrees: [
		{
			title: 'Saskatchewan Polytechnic - PGD - [Regina, SK, CA]',
			subtitle: 'Post Graduation in Cloud Computing and Blockchain',
			logo_path: 'sask_poly_logo.png',
			alt_name: 'Saskatchewan Polytechnic',
			duration: 'Dec 2023',
			descriptions: [
				"• Completed a comprehensive Post Graduation program in Cloud Computing and Blockchain at Saskatchewan Polytechnic.",
				"• Specialized in cloud computing, covering aspects such as provisioning, security, migration, and hybrid architectures.",
				"• Explored blockchain technologies, including smart contracts and integration of on-chain and off-chain components.",
				"• Key courses included Fundamentals of Cloud Computing, Fundamentals of Blockchain, Provisioning and Security, and more.",
				"• Successfully executed a special project, analyzing a business case and proposing a hybrid architecture integrating cloud services and blockchain technology.",
				"• Equipped with advanced skills to design and implement innovative solutions in the dynamic field of cloud computing and blockchain."
			],
			website_link: 'https://saskpolytech.ca/'
		},
		{
			title: 'APS College Of Engineering - VTU',
			subtitle: 'B.Tech in Electronics & Communication Engineering',
			logo_path: 'vtu_logo.png',
			alt_name: 'Vishweshwaraiah Technological University (VTU)',
			duration: '2019',
			descriptions: [
				"• Successfully completed a rigorous B.Tech program at APS College of Engineering, focusing on core engineering subjects like C, C++, Matlab, VHDL, and more.",
				"• Despite specializing in Electronics, my exposure to programming subjects ignited a keen interest in software development.",
				"• Expanded my skill set through additional courses in Core Java, PL/SQL DBMS, Flutter, React, and Full Stack Development.",
				"• This diverse educational experience has equipped me with a strong foundation in both hardware and software aspects of technology.",
				"• For more information, visit the university's website: VTU"
			],
			website_link: 'https://vtu.ac.in/en/'
		},
		{
			title: 'Vasavi JnanaPeeta Polytechnic - DTE',
			subtitle: 'Diploma in Electronics & Communication Engineering',
			logo_path: 'bte_logo.png',
			alt_name: 'Department Of Technical Education (DTE)',
			duration: '2015',
			descriptions: [
				"• Successfully completed a comprehensive diploma program at Vasavi JnanaPeeta Polytechnic, specializing in Electronics & Communication Engineering.",
				"• Acquired foundational knowledge and practical skills, laying the groundwork for my academic and professional journey.",
				"• The diploma program provided a solid understanding of the core principles of electronics and communication technology.",
				"• For more details, refer to the Department of Technical Education's website: DTE"
			],
			website_link: 'https://dtek.karnataka.gov.in/english'
		}
	]
}

const publications = {
	publications: [
		{
			title: 'Optic Disk Extraction and Hard Exudate Identification in Fundus Images Using Computer Vision and Machine Learning',
			subtitle: 'IEEE CCWC, USA',
			logo_path: 'ccwc.png',
			alt_name: 'IEEE',
			duration: 'Jan 2021',
			descriptions: [
				'Abstract:',
				"⚡ Novel ML algorithm for automated exudate and optic disk detection.",
				"⚡ Addressed the impact of sedentary lifestyles and COVID-19 on health, emphasizing diabetes and diabetic retinopathy.",
				"⚡ Proposed an advanced computer vision approach for precise optic disk elimination.",
				"⚡ Achieved over 0.18% accuracy, surpassing other algorithms.",
				"⚡ Impressive 1.82 times speed improvement compared to predecessors.",
				"⚡ Experiments and algorithms developed using MATLAB R2017a for precision."
			],
			website_link: 'https://ieeexplore.ieee.org/document/9376018'
		}
	]
}

const certifications = {
	certifications: [
		{
			title: 'Unified Java Development',
			subtitle: 'ABC for Tech Training',
			logo_path: 'abc_logo.png',
			certificate_link: 'https://drive.google.com/file/d/1dC6OFS2RNUVW-NBfnE-3hDFiGqNqdhBa/view?usp=sharing',
			alt_name: 'ABC',
			color_code: '#FFBB0099'
		},
		{
			title: 'Oops for Java',
			subtitle: 'Udemy',
			logo_path: 'udemy_logo.png',
			certificate_link: 'https://www.udemy.com/certificate/UC-5414fe71-1b10-4cdb-9bde-47086a052f03/',
			alt_name: 'ABC',
			color_code: '#d4e054'
		}
	]
}

// Experience Page
const experience = {
	title: 'Experience',
	subtitle: 'Work, Internship and Volunteership',
	description:
		'Innovative and results-driven Software Engineer with a solid academic background and 3.5 years of diverse experience in roles at TD Canada Trust, Recibo Technologies Pvt. Ltd., and WorldServe. Skilled in developing scalable applications using Node.js, Java, and Python, with a focus on mobile development using Flutter, Android Native, and cloud-based solutions. Proficient in creating user-friendly interfaces and experienced in cloud computing projects, DevOps practices, and blockchain technology. Adept at managing databases and showcasing exceptional communication, decision-making, and problem-solving skills.',
	header_image_path: 'experience.svg',
	sections: [
		{
			title: 'Work',
			experiences: [
				{
					title: 'Quality Engineer',
					company: 'TD Canada Trust',
					company_url: 'https://ca.linkedin.com/company/td/',
					logo_path: 'td.png',
					duration: 'May 2023 - Aug 2023 [ 4m ]',
					location: 'Toronto, Ontario',
					description: [
						'**Toronto-Dominion Bank** : ',
						'Spearheaded the development of **cross-browser** execution functionality for the NextGen framework, ensuring seamless performance across various browsers and platforms.',
						'Collaborated with a team in designing and implementing **robust testing strategies**, resulting in **enhanced compatibility and user experience**.',
						'Participated and **contributed to TD inner-source projects**, **successfully rolled out the solution to multiple project teams**.',
						'Debugged the **Cucable source plugin**, a critical **open-source library**, for a crutial enhancement.',
						'Innovatively optimized and expanded the existing automation suite, **increasing test coverage by 30%** and reducing manual testing efforts.',
						'**Achieved a remarkable 65% improvement** in automation execution efficiency, streamlining the testing process and accelerating product delivery.',
						'Conducted **comprehensive root cause analysis for identified defects**, collaborating with development teams to implement effective corrective measures.',
						'Actively participated in **Agile sprint planning, reviews, and retrospectives**, contributing to the overall efficiency and agility of the development process.',
						'Fostering a collaborative and knowledge-sharing environment within the quality engineering team.',
						'**Received the TD Above and Beyond! Appreciation** for outstanding performance and contribution to the organization.'
					],
					color: '#54B848'
				},
				{
					title: 'Senior Software Engineer',
					company: 'Recibo Technologies Pvt. Ltd.',
					company_url: 'https://www.linkedin.com/company/recibotech/',
					logo_path: 'recibo.png',
					duration: 'Apr 2021 - Nov 2022 [ 1y 8m ]',
					location: 'Bengaluru, Karnataka',
					description: [
						'Recibo is an **SaaS-based product company**, providing **multi-platform** application solution for clients.',
						'**Led the development and maintenance of APIs**, databases, and **Android Native/Flutter** applications, optimizing **offline sync** capabilities.',
						'Automated server operations, resulting in a **substantial reduction of the development workload by 30%**.',
						'Managed the entire **DevOps process**, including **CI/CD pipeline** and code review.',
						'**Orchestrated Mobile App releases** on Apple App Store and Google Play Store.',
						'Successfully **deployed a nationwide sales distribution application**, showcasing expertise in **SaaS architecture**.',
						'**Collaborated with clients** to determine requirements and **identify bugs** on live production environments.',
						'**Managed the system architecture** and associated **AWS services**, including **EC2, S3, RDS, and Lambda**.',
						'**Redesigned the report generation system**, increasing automation, memory management, and reducing generation time.',
						'**Built and maintained libraries** in JitPack, a **Java package repository**.',
					],
					color: '#0F79EF'
				},
				{
					title: 'Consultant - Software Developer',
					company: 'WorldServe',
					company_url: 'https://www.linkedin.com/company/worldserve-education/about/',
					logo_path: 'wse.png',
					duration: 'Jul 2020 - Feb 2021',
					location: 'Bengaluru, Karnataka',
					description:
						[
							'Designed responsive web-based templates for **interactive museum kiosk software**, contributing to a more engaging user experience.',
							'Developed **machine learning algorithms** for optic disc extraction, enhancing the accuracy of **image analysis**.',
							'Collaborated on **feature modifications for a virtual museum web app,** showcasing adaptability in a startup environment.',
							'**Designed and developed a responsive website** for a client, demonstrating expertise in **UI/UX design**.',
							'**Developed a multiplayer quiz application, file explorer, timeline, 3D museum application** for a client, showcasing expertise in **full-stack development**.',
						],
					color: '#0879bf'
				},
				{
					title: 'Embedded Software Design Engineer',
					company: 'WorldServe',
					company_url: 'https://www.linkedin.com/company/worldserve-education/about/',
					logo_path: 'wse.png',
					duration: 'Jan 2020 - Jun 2020',
					location: 'Bengaluru, Karnataka',
					description:
						[ 'My responsibility involves interfacing with hardware, As a developer, I worked on backend development for agile coding for Machine Learning algorithms collaborating with the GUI team and testing.' ],
					color: '#9b1578'
				},
				{
					title: 'Android Developer',
					company: 'Hibernate Technologies',
					company_url: 'https://www.linkedin.com/company/hibernate-technologies-pvt-ltd/about/',
					logo_path: 'hibernate.png',
					duration: 'Oct 2020 - Dec 2020',
					location: 'Work From Home',
					description:
						[ 'Hibernate Technologies is the Startup from Chennai. I have developed an E-Commerce Grocery application and integrated it with Firebase. I experienced the real-time start-up.' ],
					color: '#fc1f20'
				}
			]
		},
		{
			title: 'Internships',
			experiences: [
				{
					title: 'Technical Research Intern',
					company: 'WorldServe',
					company_url: 'https://www.linkedin.com/company/worldserve-education/about/',
					logo_path: 'wse.png',
					duration: 'Aug 2019 - Jan 2020',
					location: 'Bengaluru, Karnataka',
					description:
						[ 'I have done comprehensive analysis and gather statistics on projects and assist my seniors, major concentration was on acquiring an algorithm in finding exudates in a Fundus image I used ML for extraction Additionally, I wrote an Extensive Thesis on the findings.' ],
					color: '#c176cb'
				}
			]
		},
		{
			title: 'Volunteerships',
			experiences: [
				{
					title: 'Publicity Head & Steering Committee Member',
					company: 'PiCes journal, Germany',
					company_url: 'https://www.linkedin.com/company/perspectives-in-communication-embedded-systems-and-signal-processing/about/',
					logo_path: 'pices.png',
					duration: 'May 2020 - Jun 2020',
					location: 'Work From Home',
					description:
						[ 'I was responsible for planning the strategy to publicize the event, as this was the first large-scale competition held by Pices, our aim was to reach most aspirants as possible. The event was a success and we received an overwhelming response from distant places.' ],
					color: '#ff946b'
				}
			]
		}
	]
}

// Projects Page
const projectsHeader = {
	title: 'Projects',
	description:
		'My projects makes use of vast variety of latest technology tools. My best practice is to create clones and various different small projects and deploy them to web applications using cloud infrastructure.',
	avatar_image_path: 'projects_image.svg'
}

const projectsData = { ...ProjectsData }

// Contact Page
const contactPageData = {
	contactSection: {
		title: 'Contact Me',
		profile_image_path: 'bharath.png',
		description: 'I am available on almost every social media. You can message me, I will reply within 24 hours.'
	},

	blogSection: {
		title: "Blogs",
		subtitle: "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
		link: "https://blogs.ibsanju.com",
		avatar_image_path: "blogs_image.svg",
	},

	addressSection: {
		title: 'Address',
		subtitle: 'Canada',
		avatar_image_path: 'address_image.svg',
		location_map_link: 'https://goo.gl/maps/DscCtjZenygsKfEC7'
	},
	phoneSection: {
		title: 'Phone Number',
		subtitle: '+1-306-510-7008'
	}
}

const portfolioData = {
	settings,
	seo,
	greeting,
	socialMediaLinks,
	skills,
	competitiveSites,
	degrees,
	publications,
	certifications,
	experience,
	projectsHeader,
	projectsData,
	contactPageData,
	updatedAt: new Date(),
	createdAt: new Date("2021-04-12 08:29")
};

export { settings, seo, greeting, socialMediaLinks, skills, competitiveSites, degrees, publications, certifications, experience, projectsHeader, contactPageData, portfolioData }
