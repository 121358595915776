import { portfolioData } from '../portfolio/portfolio';

export const UPDATE_PORTFOLIO = 'UPDATE_PORTFOLIO';
export const DRAWER_OPEN = 'DRAWER_OPEN';
export const DRAWER_CLOSE = 'DRAWER_CLOSE';

function portfolioCatcher(state = {  ...portfolioData }, action) {
	switch (action.type) {
		case UPDATE_PORTFOLIO:
			return { ...state, ...action.payload };
		case DRAWER_OPEN:
			return { open: true };
		case DRAWER_CLOSE:
			return { open: false };
		default:
			return state;
	}
}
export { portfolioCatcher };
