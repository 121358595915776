import { db } from "../firebase-config";

import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  orderBy,
  limit
} from "firebase/firestore";

const bookCollectionRef = collection(db, "books");
const portfolioRef = collection(db, 'portfolio');

class BookDataService {
  addBooks = (newBook) => {
    Object.assign(newBook, {createdAt: serverTimestamp()})
    return addDoc(bookCollectionRef, newBook);
  };

  updateBook = (id, updatedBook) => {
    const bookDoc = doc(db, "books", id);
    return updateDoc(bookDoc, updatedBook);
  };

  deleteBook = (id) => {
    const bookDoc = doc(db, "books", id);
    return deleteDoc(bookDoc);
  };

  getAllBooks = () => {
    return getDocs(bookCollectionRef);
  };

  getBook = (id) => {
    const bookDoc = doc(db, "books", id);
    return getDoc(bookDoc);
  };

  getPortfolio = async () => {
    try {
      const q = query(portfolioRef, orderBy('updatedAt', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);
      return querySnapshot;
    } catch (error) {
      console.error('Error fetching latest document:', error);
    }
    return getDoc(portfolioRef);
  };

}

export default new BookDataService();
