import React, { Component } from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { connect } from 'react-redux';

// import Home from "../pages/home/HomeComponent";
// import Splash from "../pages/splash/Splash";
// import Education from "../pages/education/EducationComponent";
// import Experience from "../pages/experience/Experience";
// import Opensource from "../pages/opensource/Opensource";
// import Contact from "../pages/contact/ContactComponent";
// import Projects from "../pages/projects/Projects";

const Home = React.lazy(() => import('../pages/home/HomeComponent'));
const Education = React.lazy(() => import('../pages/education/EducationComponent'));
const Splash = React.lazy(() => import('../pages/splash/Splash'));
const Experience = React.lazy(() => import('../pages/experience/Experience'));
const Opensource = React.lazy(() => import('../pages/opensource/Opensource'));
const Contact = React.lazy(() => import('../pages/contact/ContactComponent'));
const Projects = React.lazy(() => import('../pages/projects/Projects'));

class Main extends Component {
	render() {
		// return (
		// 	<Router basename="/">
		// 		<Routes>
		// 		<Route path="/" exact element={<Home {...this.props} theme={this.props.theme} />} />

		// 			<Route path="*" element={<div>Hello</div>}/>
		// 		</Routes>
		// 	</Router>
		// );
		const { settings } = this.props.portfolio; //useSelector((state)=> state.portfolio)
		// console.log(this.props.theme.body, settings.isSplash);
		if (settings.isSplash) {
			return (
				<div>
					<Router basename="/">
						<Routes>
							<Route path="/" exact element={ <Splash {...this.props} theme={this.props.theme} />} />
							<Route path="/home" element={ <Home {...this.props} theme={this.props.theme} />} />
							<Route path="/experience" exact element={ <Experience {...this.props} theme={this.props.theme} />} />
							<Route path="/education" element={ <Education {...this.props} theme={this.props.theme} />} />
							<Route path="/opensource" element={ <Opensource {...this.props} theme={this.props.theme} />} />
							<Route path="/contact" element={ <Contact {...this.props} theme={this.props.theme} />} />
							<Route path="/splash" element={ <Splash {...this.props} theme={this.props.theme} />} />
							<Route path="/projects" element={ <Projects {...this.props} theme={this.props.theme} />} />
							<Route path="*" element={<Navigate to="/" replace={true} />} />
						</Routes>
					</Router>
				</div>
			);
		} else {
			return (
				<div>
					<Router basename="/">
						<Routes>
							<Route path="/" exact element={ <Home {...this.props} theme={this.props.theme} />} />
							<Route path="/home" element={ <Home {...this.props} theme={this.props.theme} />} />
							<Route path="/experience" exact element={ <Experience {...this.props} theme={this.props.theme} />} />
							<Route path="/education" element={ <Education {...this.props} theme={this.props.theme} />} />
							{/* <Route path="/opensource" element={ <Opensource {...this.props} theme={this.props.theme} />} /> */}
							<Route path="/contact" element={ <Contact {...this.props} theme={this.props.theme} />} />
							{/* <Route path="/splash" element={ <Splash {...this.props} theme={this.props.theme} />} /> */}
							<Route path="/projects" element={ <Projects {...this.props} theme={this.props.theme} />} />
							<Route path="*" element={<Navigate to="/" replace={true} />} />
						</Routes>
					</Router>
				</div>
			);
		}
	}
}
const mapStateToProps = (state) => {
	return {
		portfolio: state.portfolio
	};
};
export default connect(mapStateToProps)(Main);
