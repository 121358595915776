// Import the necessary Firebase modules

import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { portfolioData } from '../portfolio/portfolio';
import { app } from '../firebase-config';

const db = getFirestore(app);

// Upload data to Firestore
const uploadData = async () => {
	try {
		// Collection reference
		const portfolioRef = collection(db, 'portfolio');
				// Add document to Firestore
		const docRef = await addDoc(portfolioRef, portfolioData);
		console.log('Document written with ID: ', docRef.id);
	} catch (error) {
		console.error('Error adding document: ', error);
	}
};

// Call the uploadData function to upload the data to Firestore
export { uploadData };
