import React from "react";
import ReactDOM from "react-dom/client";
import { BaseProvider, LightTheme } from "baseui";
import { Provider as StyletronProvider } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./assests/font-awesome/css/all.css";
import { Provider } from "react-redux";
import store from "./store";

const engine = new Styletron();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <App />
      </BaseProvider>
    </StyletronProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


/**
 * To install specific version for this project
 * nvm install 16.20.2
 * nvm use 16.20.2
 * npm install -g npm@8.19.4
 * 
 * 
 * To upgrade packages
 * npx npm-check-updates -u
 * npm install
 * npm start
 */
// npx npm-check-updates -u
// npm install
// npm start